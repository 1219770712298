import React from 'react';
import ReactDOM from 'react-dom/client';
import ContactUsFormComponent from './contact-us-form.component';

document.addEventListener('DOMContentLoaded', function () {
    ReactDOM.createRoot(
        document.getElementById('contact-us-react-form') as HTMLElement
    ).render(
        <React.StrictMode>
            <ContactUsFormComponent/>
        </React.StrictMode>
    );
});