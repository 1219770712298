import React, {InputHTMLAttributes, useEffect, useState} from 'react';
import {defaultMessages, defaultRules, useValidation} from 'react-simple-form-validator';

type IContact = {
    firstName: string;
    secondName: string;
    email: string;
    phoneNumber: string;
}

interface InputProps extends InputHTMLAttributes<unknown> {
    value: string;
    validator?: {
        isFieldInError: (fieldName: string) => boolean;
        getErrorsInField: (fieldName: string) => string[];
    };
}

type IContactUsResponse = {
    messages?: {
        [field in string]: string;
    }
}

export enum EInputComponentState {
    Empty,
    Filled,
    Error,
}

const Input: React.FC<InputProps> = ({validator, value, ...props}) => {


    const [state, setState] = useState<EInputComponentState>(
        EInputComponentState.Empty,
    );

    const resetState = () => {
        if (value.length > 0) {
            setState(EInputComponentState.Filled);
        } else {
            setState(EInputComponentState.Empty);
        }
    };

    const validate = () => {
        if (validator && !validator.isFieldInError(props.name as string)) {
            setState(EInputComponentState.Filled);
        } else {
            setState((prevState) =>
                prevState === EInputComponentState.Empty
                    ? EInputComponentState.Empty
                    : EInputComponentState.Error,
            );
        }
    };

    useEffect(() => {
        resetState();
    }, [value])
    const onBlur = () => {
        // resetState();
        validate();
    };

    let borderColor = `border-brand-placeholder`;
    let textColor = `text-brand-white`;
    // let placeholderTextColor: string | undefined = tw.color('brand-placeholder');

    switch (state) {
        default:
        case EInputComponentState.Empty:
            borderColor = `grey`;
            break;
        case EInputComponentState.Error:
            borderColor = `red`;
            break;
        case EInputComponentState.Filled:
            borderColor = `white`;
            break;
    }

    return (
        <>
            <input
                {...props}
                value={value}
                onBlur={onBlur}
                style={{borderColor: borderColor}}
                className={`w-full outline-none bg-brand-dark py-4 px-6 text-sm leading-large text-brand-white rounded-lg border border-brand-white ${borderColor}`}
            />
            {state === EInputComponentState.Error && validator && validator?.getErrorsInField(props.name as string).length > 0 ? (
                <span
                    className='mt-1 text-warning-red font-thin text-xs flex flex-col justify-end'>
                    {validator?.getErrorsInField(props.name as string)[0]}
                </span>
            ) : null}
        </>
    )
}


const ContactUsFormComponent: React.FC = () => {

    const [contact, setContact] = useState<IContact>({
        firstName: '',
        secondName: '',
        email: '',
        phoneNumber: '',
    });

    const [loading, setLoading] = useState<boolean>(false);

    const validator = useValidation({
        messages: {
            en: {
                ...defaultMessages.en,
                phoneNumber: 'The field "{0}" must be a valid phone number.',
            },
        },
        labels: {
            firstName: "First name",
            secondName: "Second name",
            email: 'Email',
            phoneNumber: 'Phone number',
        },
        fieldsRules: {
            firstName: {required: true},
            secondName: {required: true},
            email: {required: true, email: true},
            phoneNumber: {required: true, phoneNumber: true},
        },
        rules: {
            ...defaultRules,
            phoneNumber: /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im,
        },
        state: contact,
    });


    const setFirstName = (firstName: string) => {
        setContact((prevState) => {
            return {...prevState, firstName: firstName}
        })
    }
    const setSecondName = (secondName: string) => {
        setContact((prevState) => {
            return {...prevState, secondName: secondName}
        })
    }
    const setEmail = (email: string) => {
        setContact((prevState) => {
            return {...prevState, email: email}
        })
    }
    const setPhoneNumber = (phoneNumber: string) => {
        setContact((prevState) => {
            return {...prevState, phoneNumber: phoneNumber}
        })
    }

    const onSubmit = async () => {
        if (!validator.isFormValid) {
            return;
        }

        const formData = new FormData();
        formData.append('first_name', contact.firstName);
        formData.append('second_name', contact.secondName);
        formData.append('email', contact.email);
        formData.append('phone_number', contact.phoneNumber);

        try {
            setLoading(true);
            const response = await fetch('/contact-us.php', {
                method: 'post',
                body: formData
            });

            const responseJson: IContactUsResponse = await response.json();
            console.log(responseJson);

            setContact({
                firstName: '',
                secondName: '',
                email: '',
                phoneNumber: '',
            });

            // @ts-ignore
            thrive.contactUsModal.open()
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    }

    return (
        <div>
            <div className="grid grid-rows-5-auto grid-cols-1 tab:grid-rows-3-auto tab:grid-cols-2 gap-6">
                <div className="row-start-1 row-end-2 col-start-1 col-end-2 relative">
                    <Input type="text"
                           validator={validator}
                           value={contact.firstName}
                           placeholder="First name"
                           name="firstName"
                           onInput={(event) => {
                               // @ts-ignore
                               setFirstName(event.target.value as string)
                           }}
                    />
                </div>
                <div
                    className="row-start-2 row-end-2 col-start-1 col-end-2 tab:tab:row-start-1 tab:row-end-2 tab:col-start-2 tab:col-end-3 relative">
                    <Input type="text"
                           validator={validator}
                           value={contact.secondName}
                           placeholder="Second name"
                           name="secondName"
                           onInput={(event) => {
                               // @ts-ignore
                               setSecondName(event.target.value as string)
                           }}
                    />
                </div>
                <div className="row-start-3 row-end-4 tab:row-start-2 tab:row-end-3 col-start-1 col-end-2 relative">
                    <Input type="email"
                           validator={validator}
                           value={contact.email}
                           placeholder="Email"
                           name="email"
                           onInput={(event) => {
                               // @ts-ignore
                               setEmail(event.target.value as string)
                           }}
                    />
                </div>
                <div
                    className="row-start-4 row-end-5 col-start-1 col-end-2 tab:row-start-2 tab:row-end-3 tab:col-start-2 tab:col-end-3 relative">
                    <Input type="tel"
                           validator={validator}
                           value={contact.phoneNumber}
                           placeholder="Phone number"
                           name="phoneNumber"
                           onInput={(event) => {
                               // @ts-ignore
                               setPhoneNumber(event.target.value as string)
                           }}
                    />
                </div>
                <div className="row-start-5 row-end-6 tab:row-start-3 tab:row-end-4 col-start-1 col-end-2">
                    <button
                        disabled={!validator?.isFormValid}
                        onClick={onSubmit}
                        className={`bg-brand-violet py-4 px-8 border rounded-lg text-brand-white text-base font-medium border-none
                         ${!validator.isFormValid ? 'cursor-not-allowed' : 'cursor-pointer'}`}>
                        {loading ? <div className="flex w-32 items-center justify-center">
                                <span className="loader h-6 w-6"></span>
                            </div> :
                            <span>Become a partner</span>}
                    </button>
                </div>
            </div>
        </div>
    );
}

export default ContactUsFormComponent;
